<template>
  <div>
    <ThemeAppHeader />

    <div class="mx-auto px-2 py-6 lg:max-w-7xl lg:px-4">
      <slot name="pageBreadcrumbs">
        <PageBreadcrumbs
          v-if="
            $route.meta.pageBreadcrumbs &&
            Array.isArray($route.meta.pageBreadcrumbs) &&
            $route.meta.pageBreadcrumbs.length > 0
          "
          :pages="$route.meta.pageBreadcrumbs"
        />
      </slot>

      <div class="mt-8">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
