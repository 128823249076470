<template>
  <header class="bg-slate-100">
    <nav
      class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <NuxtLink :to="{ name: 'index' }" class="-m-1.5 p-1.5"
          >学校教材WEB発注サービス</NuxtLink
        >
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">メニューを開く</span>
          <Bars3Icon class="size-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <NuxtLink
          v-for="(menu, i) in menus"
          :key="i"
          :to="menu.to"
          class="text-sm font-semibold leading-6 text-gray-900"
        >
          {{ menu.name }}
        </NuxtLink>
      </div>
    </nav>
    <Dialog
      as="div"
      class="lg:hidden"
      :open="mobileMenuOpen"
      @close="mobileMenuOpen = false"
    >
      <div class="fixed inset-0 z-10" />
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-slate-100 p-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <NuxtLink :to="{ name: 'index' }" class="-m-1.5 p-1.5">
            学校教材WEB発注サービス
          </NuxtLink>
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = false"
          >
            <span class="sr-only">メニューを閉じる</span>
            <XMarkIcon class="size-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <NuxtLink
                v-for="(menu, i) in menus"
                :key="i"
                :to="menu.to"
                class="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 tracking-wide text-gray-900 hover:bg-gray-50"
                @click="mobileMenuOpen = false"
              >
                {{ menu.name }}
              </NuxtLink>
              <div
                v-if="auth.user"
                class="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 tracking-tighter text-gray-500"
              >
                {{ auth.user.lastName }}先生 (ID:<span class="select-all">{{
                  auth.user.teacherProfile.customerId
                }}</span
                >) でログイン中
              </div>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { isPresent } from 'ts-is-present';

import type { RouteLocationRaw } from '#vue-router';
import { userInfoSchema } from '~/schemas/user_info_schema';

import type { UserInfo } from '~/schemas/user_info_schema';

const menus: { name: string; to: RouteLocationRaw }[] = [
  {
    name: '教材検索',
    to: {
      name: 'products-search',
    },
  },
  {
    name: '見積・発注依頼前リスト',
    to: {
      name: 'orders-checkout',
    },
  },
  {
    name: '発注履歴',
    to: {
      name: 'orders-history',
    },
  },
  {
    name: 'ユーザ情報変更',
    to: {
      name: 'accounts-profile',
    },
  },
  {
    name: 'Q&A',
    to: {
      name: 'faq',
    },
  },
  {
    name: 'ログアウト',
    to: {
      name: 'accounts-logout',
    },
  },
];

// ref
const auth = ref({
  user: null as UserInfo | null,
});
const mobileMenuOpen = ref(false);

// fetch
const data = await GqlGetProfile();
if (data.me) {
  auth.value.user = userInfoSchema.parse({
    ...data.me,
    teacherProfile: {
      ...data.me.teacherProfile,
      subjectAreas:
        data.me.teacherProfile?.subjectAreas?.edges
          .filter(isPresent)
          .map((edge) => edge.node)
          .filter(isPresent)
          .map((area) => ({
            ...area,
            id: area.id,
            name: area.name,
          })) ?? [],
    },
    teacherAgents: data.me.teacherAgents?.edges
      .filter(isPresent)
      .map((edge) => edge.node)
      .filter(isPresent),
  });
}
</script>
